/*!
 * bsStepper v{version} (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - {year} Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */

.bs-stepper .step-trigger {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 16px 0px;
  color: #6c757d;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: rgb(246 246 246 / 0%);
  border: none;
  border-radius: .25rem;
  transition: background-color .15s ease-out, color .15s ease-out;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bs-stepper .step-trigger:disabled,
.bs-stepper .step-trigger.disabled {
  pointer-events: none;
  opacity: .65;
}

.bs-stepper .step-trigger:focus {
  color: #007bff;
  outline: none;
}

.bs-stepper .step-trigger:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, .0);
}


.bs-stepper-label {
  display: inline-block;
  margin: .25rem;
}

.bs-stepper-header {
  display: flex;
  align-items: center;
}


.bs-stepper-line,
.bs-stepper .line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, .12);
}

.bs-stepper.vertical .bs-stepper-content .content:not(.active), .bs-stepper.vertical .bs-stepper-header .line {
	display: none
}

.bs-stepper-circle {
  width: 2.7rem;
  height: 2.7rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #969595;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.step .steper-title {
  font-size: 17px;
}

.step .steper-sub-title {
  font-size: 13px;
}

.active .bs-stepper-circle {
  color: #ffffff;
  background-color: #007bff;
}

.bs-stepper-content {
  padding: 0;
}


@media (max-width:992px) { 
  .bs-stepper.vertical {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.bs-stepper.vertical .bs-stepper-header {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start
	}
}

@media (max-width: 520px) {
  .bs-stepper-content {
    padding: 0;
  }
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0;
    padding: 1rem;
    background-color: #fbfbfb;
    border-right: 1px solid #EBE9F1;
    border-bottom: none;
    height: auto;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
  display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: .3s;
  transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
  display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
  display: none;
}


/* dark theme */

html.dark-theme .bs-stepper.vertical .bs-stepper-header{
    background-color: #31373c;
    border-right: 1px solid rgb(255 255 255 / 12%);
}


html.dark-theme .step .steper-title {
  color: #cdcdce;
}

html.dark-theme .step .steper-sub-title {
  color: #8f8f8f;
}

html.dark-theme .bs-stepper-circle {
  color: #c7cdcf;
  background-color: #212529;
}