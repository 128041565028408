
/* ====================== [ Start Call To Action  ] ====================== */
.call-action {
    font-family: "Impulse" !important;
}

.call-action p {
	color: #999;
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 10px; }
   .call-action form .form-group {
	position: relative;
	margin: 0;
	padding: 0;
	display: -webkit-box;   
	display: -ms-flexbox;
	display: flex; 
	font-size: 20px;}
   .call-action form input {
	padding: 15px;
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	border: 0;
	color: #fff; }
  .call-action form button {
	border: 0;
	padding: 15px 30px;
	background: rgba(255, 255, 255, 0.2);
	color: #03dac6;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 20px;
	z-index: 2;
	cursor: pointer; }
    .call-action .container {
        width: 150%;
        padding: 50px;
        background-image: url('../images/login-bg.png');
    }
    .call-action .content {
        margin-left: 50px;
    }
.call-action .content h6 {
    color: #03dac6;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 10px;
 }
    .call-action .content h6 span {
      color: #03dac6; }
  
  .call-action .content h2 {
    font-weight: 700;
    font-size: 4vw;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 20px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    color: transparent; }
    .call-action .content h2 span {
      color: transparent; }
    .call-action .content h2 b span {
      color: #fff;
      -webkit-text-stroke-width: 0; }
  
  .call-action .content p {
    font-size: 17px;
    font-weight: 300; }
  
  .call-action .btn-curve {
    border: 2px solid;
    line-height: 2; }
    .call-action .btn-curve span {
      font-size: 15px;
      font-weight: 700;
      text-transform: capitalize; }
    .call-action .btn-curve:hover span {
      color: #0c0f16; }
  
  .call-action .tlink {
    margin-left: auto;
    border-bottom: 1px solid #eee; }
  
  .call-action.next {
    position: relative;
    z-index: 2;
    overflow: hidden; }
    .call-action.next:after {
      display: none; }
    .call-action.next:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: #0a0a0a;
      opacity: 1;
      -webkit-transition: all .5s;
      -o-transition: all .5s;
      transition: all .5s; }
    .call-action.next .content h2 {
      font-size: 5vw;
      font-weight: 700; }
      .call-action.next .content h2 b {
        font-weight: 700; }
    .call-action.next .nxt-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: scale(1.4);
      -ms-transform: scale(1.4);
      transform: scale(1.4);
      background-size: cover;
      background-position: center;
      z-index: -1;
      -webkit-transition: all 1s linear;
      -o-transition: all 1s linear;
      transition: all 1s linear; }
    .call-action.next:hover:before {
      opacity: .9; }
    .call-action.next:hover .nxt-img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 10s linear;
      -o-transition: all 10s linear;
      transition: all 10s linear; }
  
  /* ====================== [ End Call To Action  ] ====================== */