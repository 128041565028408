/* Sidebar link with padding and flexbox to align icon and text */
.sidebar-link {
    display: flex;
    align-items: center;
    padding: 10px 20px; /* Adjust padding to match your design */
    color: inherit;
    text-decoration: none;
  }
  
  /* Icon within the link with margin */
  .sidebar-link .menu-icon {
    margin-right: 10px; /* Adjust spacing between icon and text */
  }
  
  /* Dropdown animation */
  .dropdown-animate {
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    max-height: 0; /* Initial state for animation */
    padding-top: 0; /* Remove padding when collapsed */
    padding-bottom: 0;
  }
  
  .dropdown-animate.open {
    max-height: 1000px; /* Set to the maximum height your dropdown can have */
    padding-top: 10px; /* Add padding when expanded */
    padding-bottom: 10px;
  }
  