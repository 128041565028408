/* Chat preview container adjustments */
.chat-preview {
  font-family: 'Impulse' !important;
  margin-top: 15px; /* Adjust as needed to align with the header */
  width: 100%; /* Adjust width as needed */
  max-width: 300px; /* Or whatever max width you want */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden; /* Ensures the child elements do not overflow the rounded corners */
  margin-left: auto;
  margin-right: auto;
}

/* Ensure chat preview is displayed correctly when open */

/* Chat conversation within the preview */
.chat-conversation {

  border-radius: 10px;
  height: 400px; /* Adjust as needed for your layout */
  overflow-y: auto; /* Allow scrolling within the chat */
}
.chat-conversation.dark{
  background-color: #151921 ;
}
.chat-conversation.light{
  background-color: white;
}
/* Header of the chat */
.chat-conversation.light .chat-header {
  background-color: #4dc1b8;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  color: white;
  padding: 15px;
  cursor: pointer;
}
.chat-conversation.dark .chat-header {
  background-color: #4dc1b8;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  color: black;
  padding: 15px;
  cursor: pointer;
}
/* Where the messages will appear */
.chat-messages {
  padding: 10px;
  flex-grow: 1; /* Take available space */
  height: 70%;
  overflow: none;
  overflow-y: scroll;
}
.chat-messages::-webkit-scrollbar-track {
  background: none;
}

/* Style the scrollbar thumb */
.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* White with 0.2 opacity */
  border-radius: 10px; /* Rounded corners */
}

/* Optional: Adjust scrollbar width and height */
.chat-messages::-webkit-scrollbar {
  width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
}
/* Individual messages styling */
.message {
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 15px;

  border-radius: 20px;
  background-color: #f1f1f1; /* Default background for bot messages */
}

/* User's own messages */
.user-message {
  background-color: #4dc1b8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #384357;
  align-self: flex-end;
}

/* Bot's messages */
.chat-conversation.light .bot-message {
  align-self: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #384357;
  background-color: white;
}
.chat-conversation.dark .bot-message {
  align-self: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;;
  background-color: #384357;
}
.bot-preview-container{
  z-index: 1000 !important;
  min-width: 300px;
  overflow: hidden;
}
/* Typing indicator animation */
.typing-indicator {
  margin: 10px;
}

.typing-indicator .dot {
  height: 8px;
  width: 8px;
  background-color: #aaa;
  border-radius: 50%;
  animation: bounce 1s infinite alternate;
}

/* Keyframes for the bounce effect of typing indicator */
@keyframes bounce {
  to {
    transform: translateY(-50%);
  }
}

/* Input area of the chat */
.chat-input {
  /* border-top: 1px solid #ccc; */
  padding: 10px;
  display: flex;
  position: fixed;
}
.chat-input::-webkit-scrollbar-track {
  background: none;
}

/* Style the scrollbar thumb */
.chat-input::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2); /* White with 0.2 opacity */
  border-radius: 10px; /* Rounded corners */
}

/* Optional: Adjust scrollbar width and height */
.chat-input::-webkit-scrollbar {
  width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
}
/* Input field */
.chat-conversation.light .chat-input textarea {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 30px;
  max-height: 50px;
  width: 217px;
  transition: border-color 0.3s ease; 
}
.chat-conversation.dark .chat-input textarea {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #384357;
  background-color: #384357;
  color: white;
  border-radius: 5px;
  width: 217px;
  min-height: 30px;
  max-height: 50px;
  transition: border-color 0.3s ease; 
}
.chat-input textarea:focus {
  border: 2px solid #4dc1b8;
  outline: none;

}

/* Send button */
.chat-input button {
  padding: 10px 20px;
  background-color: #4dc1b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .bot-preview-container  {
  min-width: 90vw;
}
.chat-preview{
  max-width: 80vw;
}
.chat-input{
  width: 80vw;
}
.chat-conversation {
  border-radius: 10px;
  height: 80vh;
  overflow-y: auto;
}
.chat-messages {
  height: 67vh;
}
}