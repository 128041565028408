.App {
  display: flex;
}

.content {
  flex-grow: 1; /* Ensures that content area takes up remaining space */
  padding: 1rem; /* Spacing around the content */
  margin-left: 250px; /* Space for the sidebar */
}

/* You may need additional responsive styles */
@media (max-width: 768px) {
  .content {
    margin-left: 0; /* Content takes full width on smaller screens */
  }
}
